<template>
  <div class="modal fade" id="wallet-history-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content" v-if="data">
        <div class="modal-header">
          <h5 class="modal-title text-capitalize" id="exampleModalLabel">{{ type }} Wallet History  <b>${{ data.current.toFixed(2) }}</b></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-7">
              <table class="table table-bordered table-sm">
                <thead>
                <tr>
                  <th class="text-center text-capitalize" colspan="5">{{ type }} History</th>
                </tr>
                <tr v-if="type === 'gross'">
                  <th>Start</th>
                  <th>End</th>
                  <th>Worked</th>
                  <th class="text-right">Hourly Pay</th>
                  <th class="text-right">Gross</th>
                </tr>
                <tr v-else-if="type === 'tip'">
                  <th>Date</th>
                  <th>Invoice No.</th>
                  <th class="text-right">Amount</th>
                  <th class="text-right">Tip</th>
                </tr>
                <tr v-else-if="type === 'bonus'">
                  <th>Date</th>
                  <th>Invoice No.</th>
                  <th class="text-right">Amount</th>
                  <th class="text-right">Commission (%)</th>
                  <th class="text-right">Commission</th>
                </tr>
                <tr v-else-if="type === 'wow'">
                  <th>Date</th>
                  <th>Type</th>
                  <th class="text-right">Amount</th>
                </tr>
                </thead>
                <tbody v-if="type === 'gross'">
                <tr v-for="history in data.histories" :key="'history_'+history.id">
                  <td>{{ history.start }}</td>
                  <td>{{ history.end }}</td>
                  <td>{{ history.worked }}</td>
                  <td class="text-right">${{ history.hourly_pay }}</td>
                  <td class="text-right">${{ history.gross.toFixed(2) }}</td>
                </tr>
                </tbody>
                <tbody v-else-if="type === 'tip'">
                <tr v-for="history in data.histories" :key="'history_'+history.id">
                  <td>{{ history.date }}</td>
                  <td>{{ history.invoice_no }}</td>
                  <td class="text-right">${{ history.amount.toFixed(2) }}</td>
                  <td class="text-right">${{ history.tip.toFixed(2) }}</td>
                </tr>
                </tbody>
                <tbody v-else-if="type === 'bonus'">
                <tr v-for="history in data.histories" :key="'history_'+history.id">
                  <td>{{ history.date }}</td>
                  <td>{{ history.invoice_no }}</td>
                  <td class="text-right">${{ history.amount.toFixed(2) }}</td>
                  <td class="text-right">{{ history.commission_percentage }}%</td>
                  <td class="text-right">${{ history.commission.toFixed(2) }}</td>
                </tr>
                </tbody>
                <tbody v-else-if="type === 'wow'">
                <tr v-for="history in data.histories" :key="'history_'+history.id">
                  <td>{{ history.date }}</td>
                  <td>{{ history.type }}</td>
                  <td class="text-right">${{ history.amount.toFixed(2) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-5">
              <table class="table table-bordered table-sm">
                <thead>
                <tr>
                  <th class="text-center" colspan="2">Withdrawal</th>
                </tr>
                <tr>
                  <th>Created At</th>
                  <th>Type</th>
                  <th class="text-right">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="withdrawal in data.withdrawals" :key="'withdrawal_'+withdrawal.id">
                  <td>{{ withdrawal.created_at }}</td>
                  <td>{{ getWithdrawalType(withdrawal) }}</td>
                  <td class="text-right text-danger">-${{ withdrawal.amount }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      type: '',
      data: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('wallet-history-modal'))
  },
  methods: {
    show(type, data) {
      this.type = type;
      this.data = data;

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    getWithdrawalType(withdrawal) {
      switch (withdrawal.type) {
        case 'wow':
          return 'Wow';
        case 'tip':
          return 'Tip';
        case 'commission':
          return 'Bonus';
        case 'gross':
          return 'Gross';
      }
    }
  }
}
</script>