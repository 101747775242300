<template>
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div :class="'card-header ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">{{ title }}</div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-bordered text-center">
                  <thead>
                  <tr>
                    <th style="vertical-align: middle; text-align: center" rowspan="2">Name</th>
                    <th class="bg-light-yellow" colspan="2">#</th>
                    <th class="bg-light-blue" colspan="2">Total</th>
                    <th class="bg-light-green" colspan="2">Average</th>
                    <th class="bg-light-pink" colspan="9">Score</th>
                    <!--                  <th class="bg-light-blue">Score</th>-->
                    <!--                  <th class="bg-light-green" colspan="6">Amount</th>-->
                    <!--                  <th class="bg-light-pink" colspan="2">Average Job</th>-->
                  </tr>
                  <tr>
                    <th class="bg-light-yellow">
                      Jobs

                      <span class="ml-1 text-muted"
                            @click="showDetails('Jobs', true, false)">
                      <i class="fas fa-info-circle"></i>
                    </span>
                    </th>
                    <th class="bg-light-yellow">
                      Wow
                    </th>
                    <th class="bg-light-blue">
                      Revenue

                      <span class="ml-1 text-muted"
                            @click="showDetails('Revenue', true, true)">
                      <i class="fas fa-info-circle"></i>
                    </span>
                    </th>
                    <th class="bg-light-blue">Frenchie</th>
                    <th class="bg-light-green">Job Cost</th>
                    <th class="bg-light-green">COGS</th>
                    <th class="bg-light-pink">Wow</th>
                    <th class="bg-light-pink">Labour</th>
                    <th class="bg-light-pink">Frenchie</th>
                    <th class="bg-light-pink">Lease</th>
                    <th class="bg-light-pink">Gas</th>
                    <th class="bg-light-pink">Service</th>
                    <th class="bg-light-pink">Repairs</th>
                    <th class="bg-light-pink">Supplies</th>
                    <th class="bg-light-pink">COGS</th>
                    <!--                  <th class="bg-light-blue">
                                        Almighty Truck

                                        <span class="ml-1 text-muted"
                                              @click="showDetails('Almighty Truck', true, false)">
                                          <i class="fas fa-info-circle"></i>
                                        </span>
                                      </th>-->
                    <!--                  <th class="bg-light-green">
                                        Revenue

                                        <span class="ml-1 text-muted"
                                              @click="showDetails('Revenue', true, true)">
                                          <i class="fas fa-info-circle"></i>
                                        </span>
                                      </th>
                                      <th class="bg-light-green">
                                        Supplies

                                        <span class="ml-1 text-muted"
                                              @click="showDetails('Supplies', false, true, 'Supply')">
                                          <i class="fas fa-info-circle"></i>
                                        </span>
                                      </th>
                                      <th class="bg-light-green">
                                        Gas

                                        <span class="ml-1 text-muted"
                                              @click="showDetails('Gas', false, true, 'Gas')">
                                          <i class="fas fa-info-circle"></i>
                                        </span>
                                      </th>
                                      <th class="bg-light-green">
                                        Service

                                        <span class="ml-1 text-muted"
                                              @click="showDetails('Service', false, true, 'Service')">
                                          <i class="fas fa-info-circle"></i>
                                        </span>
                                      </th>
                                      <th class="bg-light-green">
                                        Repairs

                                        <span class="ml-1 text-muted"
                                              @click="showDetails('Repairs', false, true, 'Repair')">
                                          <i class="fas fa-info-circle"></i>
                                        </span>
                                      </th>
                                      <th class="bg-light-green">
                                        Frenchie

                                        <span class="ml-1 text-muted"
                                              @click="showDetails('Repairs', true, false, null)">
                                          <i class="fas fa-info-circle"></i>
                                        </span>
                                      </th>
                                      <th class="bg-light-pink">Amount</th>
                                      <th class="bg-light-pink">Cost</th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="truckData in data.trucks"
                            :key="'truck_data_'+truckData.id">
                    <tr v-if="!hideTrucks.includes(truckData.id)">
                      <td>
                        <a href="#"
                           @click.prevent="hideTruckHandler(truckData.id)"
                           class="text-danger mr-1 text-sm"
                           v-if="!filters.trucks.length">X</a>

                        {{ truckData.name }}
                      </td>
                      <td class="bg-light-yellow">
                        {{ truckData.total_job }}

                        <span class="ml-1 text-muted"
                              @click="showDetails('Jobs', true, false, null, truckData.id)">
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td class="bg-light-yellow">
                        {{ truckData.wow }}
                      </td>
                      <!--                    <td class="bg-light-blue">
                                            {{ truckData.almighty_truck.toFixed(2) }}%

                                            <span class="ml-1 text-muted"
                                                  @click="showDetails('Almighty Truck', true, false, null, truckData.id)">
                                            <i class="fas fa-info-circle"></i>
                                          </span>
                                          </td>-->
                      <td class="bg-light-blue">
                        ${{ truckData.revenue.toFixed(2) }}

                        <span class="ml-1 text-muted"
                                  @click="showDetails('Revenue', true, true, null, truckData.id)">
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </td>
                      <td class="bg-light-blue">${{ truckData.frenchie.toFixed(2) }}</td>
                      <td class="bg-light-green">${{ truckData.average_job_cost }}</td>
                      <td class="bg-light-green">${{ truckData.cogs }}</td>
                      <td class="bg-light-pink"></td>
                      <td class="bg-light-pink"></td>
                      <td class="bg-light-pink">{{ truckData.frenchie_percentage }}%</td>
                      <td class="bg-light-pink"></td>
                      <td class="bg-light-pink">{{ truckData.gas_percentage }}%</td>
                      <td class="bg-light-pink">{{ truckData.service_percentage }}%</td>
                      <td class="bg-light-pink">{{ truckData.repair_percentage }}%</td>
                      <td class="bg-light-pink">{{ truckData.supplies_percentage }}%</td>
                      <td class="bg-light-pink">{{ truckData.cogs_percentage }}%</td>
                      <!--                    <td class="bg-light-green">
                                            ${{ truckData.supplies_amount.toFixed(2) }}

                                            <span class="ml-1 text-muted"
                                                  @click="showDetails('Supplies', false, true, 'Supply', truckData.id)">
                                            <i class="fas fa-info-circle"></i>
                                          </span>
                                          </td>
                                          <td class="bg-light-green">
                                            ${{ truckData.gas_amount.toFixed(2) }}

                                            <span class="ml-1 text-muted"
                                                  @click="showDetails('Gas', false, true, 'Gas', truckData.id)">
                                            <i class="fas fa-info-circle"></i>
                                          </span>
                                          </td>
                                          <td class="bg-light-green">
                                            ${{ truckData.service_amount.toFixed(2) }}

                                            <span class="ml-1 text-muted"
                                                  @click="showDetails('Service', false, true, 'Service', truckData.id)">
                                            <i class="fas fa-info-circle"></i>
                                          </span>
                                          </td>
                                          <td class="bg-light-green">
                                            ${{ truckData.repair_amount.toFixed(2) }}

                                            <span class="ml-1 text-muted"
                                                  @click="showDetails('Repairs', false, true, 'Repair', truckData.id)">
                                              <i class="fas fa-info-circle"></i>
                                            </span>
                                          </td>
                                          <td class="bg-light-green">
                                            ${{ truckData.frenchie.toFixed(2) }}

                                            <span class="ml-1 text-muted"
                                                  @click="showDetails('Frenchie', true, false, null, truckData.id)">
                                              <i class="fas fa-info-circle"></i>
                                            </span>
                                          </td>
                                          <td class="bg-light-pink">${{ truckData.average_job }}</td>
                                          <td class="bg-light-pink">${{ truckData.average_job_cost }}</td>-->
                    </tr>
                  </template>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th></th>
                    <th class="bg-light-yellow">{{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.total_job, 0) }}</th>
                    <th class="bg-light-yellow">{{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.wow, 0) }}</th>
                    <th class="bg-light-blue">${{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.revenue, 0).toFixed(2) }}</th>
                    <th class="bg-light-blue">${{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.frenchie, 0).toFixed(2) }}</th>
                    <th class="bg-light-green"></th>
                    <th class="bg-light-green"></th>
                    <th class="bg-light-pink"></th>
                    <th class="bg-light-pink"></th>
                    <th class="bg-light-pink"></th>
                    <th class="bg-light-pink"></th>
                    <th class="bg-light-pink"></th>
                    <th class="bg-light-pink"></th>
                    <th class="bg-light-pink"></th>
                    <th class="bg-light-pink"></th>
                    <th class="bg-light-pink"></th>
                    <!--                  <th class="bg-light-blue"></th>
                                      <th class="bg-light-green">${{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.supplies_amount, 0).toFixed(2) }}</th>
                                      <th class="bg-light-green">${{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.gas_amount, 0).toFixed(2) }}</th>
                                      <th class="bg-light-green">${{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.service_amount, 0).toFixed(2) }}</th>
                                      <th class="bg-light-green">${{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.repair_amount, 0).toFixed(2) }}</th>
                                      <th class="bg-light-green">${{ data.trucks.filter(truck => !hideTrucks.includes(truck.id)).reduce((a, b) => a + b.frenchie, 0).toFixed(2) }}</th>
                                      <th class="bg-light-pink"></th>
                                      <th class="bg-light-pink"></th>-->
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <TruckReportDetailsModal ref="truckReportDetailsModal"
                           :id="Math.random() * 100" />
</template>
<script>
import TruckReportDetailsModal from "@/views/report/components/modals/TruckReportDetailsModal.vue";

export default {
  props: ['data', 'title', 'filters', 'hideTrucks'],
  components: {TruckReportDetailsModal},
  methods: {
    hideTruckHandler(truckId) {
      this.$emit('hideTruckHandler', truckId);
    },
    showDetails(title, showJobs, showMaintenances, type = null, truckId = null) {
      let allMaintenances = this.data.all_maintenances;
      let allJobs = this.data.all_jobs;


      if (truckId) {
        allMaintenances = this.data.trucks.find(truck => truck.id == truckId).all_maintenances;
        allJobs = this.data.trucks.find(truck => truck.id == truckId).all_jobs;
      }

      if (type) {
        allMaintenances = allMaintenances.filter(item => item.type === type);
      }

      this.$refs.truckReportDetailsModal.show(title, allJobs, allMaintenances, showJobs, showMaintenances);
    }
  }
}
</script>