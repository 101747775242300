<template>
  <div class="modal fade" id="gross-wallet-history-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Last 30 Days Transactions</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <table class="table table-bordered table-sm">
                <thead>
                <tr>
                  <th class="text-center" colspan="5">Gross Pay</th>
                </tr>
                <tr>
                  <th>Start</th>
                  <th>End</th>
                  <th>Worked</th>
                  <th class="text-right">Hourly Pay</th>
                  <th class="text-right">Gross</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="history in histories" :key="'history_'+history.id">
                  <td>{{ history.start }}</td>
                  <td>{{ history.end }}</td>
                  <td>{{ history.worked }}</td>
                  <td class="text-right">${{ history.hourly_pay }}</td>
                  <td class="text-right">${{ history.gross.toFixed(2) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6">
              <table class="table table-bordered table-sm">
                <thead>
                <tr>
                  <th class="text-center" colspan="2">Withdrawal</th>
                </tr>
                <tr>
                  <th>Created At</th>
                  <th class="text-right">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="withdrawal in withdrawals" :key="'withdrawal_'+withdrawal.id">
                  <td>{{ withdrawal.created_at }}</td>
                  <td class="text-right text-danger">-${{ withdrawal.amount }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      type: '',
      histories: [],
      withdrawals: []
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('gross-wallet-history-modal'))
  },
  methods: {
    show(type, technicianId) {
      this.type = type;
      this.loadData(type, technicianId);
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadData(type, technicianId) {
      this.histories = [];
      this.withdrawals = [];

      this.axios.get('/reports/technician-wallet/history/' + type, {
        params: {
          technician: technicianId
        }
      }).then(res => {
        this.histories = res.data.data.histories;
        this.withdrawals = res.data.data.withdrawals;
      })
    }
  }
}
</script>