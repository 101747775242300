<template>
  <div class="modal fade"
       :id="'truck-report-details-modal-'+id"
       tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div :class="this.showMaintenances ? 'col-6' : 'col-12'"
                 v-if="this.showJobs">
              <table class="table table-bordered table-sm">
                <thead>
                <tr>
                  <th>SL</th>
                  <th>Date</th>
<!--                  <th>Company</th>-->
                  <th>Invoice No.</th>
                  <th class="text-right">Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(job, i) in jobs" :key="'job_'+job.id">
                  <td>{{ i+1 }}</td>
                  <td>{{ job.formatted_date }}</td>
<!--                  <td>{{ job.company.name }}</td>-->
                  <td>{{ job.invoice_no }}</td>
                  <td class="text-right">${{ job.amount }}</td>
                </tr>
                <tr>
                  <th colspan="3">Total</th>
                  <th class="text-right">${{ jobs.reduce((a, b) => a + b.amount, 0).toFixed(2) }}</th>
                </tr>
                </tbody>
              </table>
            </div>
            <div :class="this.showJobs ? 'col-6' : 'col-12'"
                 v-if="this.showMaintenances">
              <table class="table table-bordered table-sm">
                <thead>
                <tr>
                  <th>Date</th>
                  <th>Equipment</th>
                  <th>Type</th>
                  <th class="text-right">Amount</th>
                  <th class="text-right">Amount Without HST</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="maintenance in maintenances" :key="'maintenance_'+maintenance.id">
                  <td>{{ maintenance.formatted_date }}</td>
                  <td>{{ maintenance.equipment.name }}</td>
                  <td>{{ maintenance.type }}</td>
                  <td class="text-right">${{ maintenance.amount }}</td>
                  <td class="text-right">${{ maintenance.amount_without_hst }}</td>
                </tr>
                <tr>
                  <th colspan="3">Total</th>
                  <th class="text-right">${{ maintenances.reduce((a, b) => a + b.amount, 0).toFixed(2) }}</th>
                  <th class="text-right">${{ maintenances.reduce((a, b) => a + b.amount_without_hst, 0).toFixed(2) }}</th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      title: "Report",
      modal: null,
      jobs: [],
      maintenances: [],
      showJobs: true,
      showMaintenances: true,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('truck-report-details-modal-'+this.id))
  },
  methods: {
    show(title = "", jobs = [], maintenances = [], showJobs = true, showMaintenances = true) {
      this.title = title;
      this.jobs = jobs;
      this.maintenances = maintenances;
      this.showJobs = showJobs;
      this.showMaintenances = showMaintenances;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    }
  }
}
</script>