<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-4">
          <h1 class="m-0">Technician Summary Report</h1>
        </div><!-- /.col -->
        <div class="col-sm-5">
          <select class="select2"
                  id="technicians"
                  multiple="multiple"
                  data-placeholder="Select Technicians"
                  style="width: 100%;">
            <option :value="technician.id"
                    v-for="technician in technicians"
                    :key="'tech_'+technician.id">{{ technician.name }}</option>
          </select>
        </div>
        <div class="col-sm-3">
          <div id="entry-date-range" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
            <i class="fa fa-calendar"></i>&nbsp;
            <span></span> <i class="fa fa-caret-down"></i>
          </div>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-12">
              <button class="btn btn-sm btn-info"
                      :disabled="loading"
                      @click="loadAllReports">Show All Technician</button>
            </div>
          </div>

          <h6 class="text-center" v-if="loading">Loading...</h6>
          <div class="table-responsive" v-if="!loading && reports.length">
            <table class="table table-bordered text-center">
              <thead>
              <tr>
                <th></th>
                <th class="text-center bg-light-blue" colspan="3">Wallet</th>
                <th class="text-center bg-light-green" colspan="5">Amount</th>
                <th class="text-center bg-light-yellow" colspan="2">Score</th>
                <th class="text-center bg-light-pink" colspan="2">Hours</th>
                <th class="text-center bg-light-grey" colspan="2">Monty</th>
              </tr>
              <tr>
                <th class="text-left">Name</th>
                <th class="bg-light-blue">Bonus</th>
                <th class="bg-light-blue">Wow</th>
                <th class="bg-light-blue">Tips</th>
                <th class="bg-light-green">Gross Pay</th>
                <th class="bg-light-green">Wk Production</th>
                <th class="bg-light-green">Hr Production</th>
                <th class="bg-light-green">Wow</th>
                <th class="bg-light-green">Tips</th>
                <th class="bg-light-yellow">Power</th>
                <th class="bg-light-yellow">Wow</th>
                <th class="bg-light-pink">Worked Weekly</th>
                <th class="bg-light-pink">Average Weekly</th>
                <th class="bg-light-grey">Full</th>
                <th class="bg-light-grey">Hourly</th>
              </tr>
              </thead>

              <tbody>
              <template v-for="report in reports" :key="'report_'+report.id">
                <tr v-if="!hideTechnicians.includes(report.id)">
                  <td class="text-left">
                    <a href="#"
                       @click.prevent="hideTechnicianHandler(report.id)"
                       class="text-danger mr-1 text-sm"
                       v-if="!filters.technicians.length">X</a>
                    {{ report.name }}
                  </td>
                  <td :class="`bg-light-blue ${report.wallet.commission < 0 && 'text-danger'}`">${{ report.wallet.commission.toFixed(2) }}</td>
                  <td :class="`bg-light-blue ${report.wallet.wow < 0 && 'text-danger'}`">${{ report.wallet.wow.toFixed(2) }}</td>
                  <td :class="`bg-light-blue ${report.wallet.tip < 0 && 'text-danger'}`">${{ report.wallet.tip.toFixed(2) }}</td>
                  <td class="bg-light-green">${{ report.gross_pay.toFixed(2) }}</td>
                  <td class="bg-light-green">{{ report.production_amount.toFixed(2) }}</td>
                  <td class="bg-light-green">${{ report.hourly_production_score.toFixed(2) }}</td>
                  <td class="bg-light-green">${{ report.wow_amount.toFixed(2) }}</td>
                  <td class="bg-light-green">${{ report.tip_amount.toFixed(2) }}</td>
                  <td class="bg-light-yellow">{{ report.power_score }}</td>
                  <td class="bg-light-yellow">{{ report.wow_score.toFixed(2) }}%</td>
                  <td class="bg-light-pink">{{ report.hours_worked }}</td>
                  <td class="bg-light-pink">{{ report.avg_weekly_hr }}</td>
                  <td class="bg-light-grey">${{ report.full_monty.toFixed(2) }}</td>
                  <td class="bg-light-grey">${{ report.hourly_monty.toFixed(2) }}</td>
                </tr>
              </template>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      reports: [],
      hideTechnicians: [],
      technicians: [],
      filters: {
        technicians: [],
        date: ''
      },
    }
  },
  async mounted() {
    await this.loadTechnicians();
    this.initDateRange();
    this.initSelect2();
  },
  methods: {
    async loadTechnicians() {
      await this.axios.get('/technicians')
          .then((response) => {
            this.technicians = response.data.data
          });
    },
    initDateRange() {
      let start = window.moment().startOf('week');
      let end = window.moment();
      let self = this;

      window.$('#entry-date-range').daterangepicker(
          {
            autoUpdateInput: false,
            startDate: start,
            endDate: end,
            locale: {
              cancelLabel: 'Clear',
              format: 'MMM D, YYYY'
            },
            ranges: {
              'This Week': [window.moment().startOf('week'), window.moment()],
              'Today': [window.moment(), window.moment()],
              'Last Week': [window.moment().subtract(7, 'days').startOf('week'), window.moment().subtract(7, 'days').endOf('week')],
              'Last 30 Days': [window.moment().subtract(29, 'days'), window.moment()],
              'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
              'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')],
              'This Year': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment()],
              'Year to Last Week': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment().subtract(1, 'weeks').endOf('week')],
            }
          },
          function (start, end) {
            self.changeDateRange(start, end);
          }
      )

      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
    },
    initSelect2() {
      let self = this;

      window.$('.select2').select2();

      window.$('#technicians').on('change.select2', function () {
        self.filters.technicians = window.$(this).val();
        self.loadReports();
      });


      const defaultUsers = this.technicians.filter(t => t.include_in_default_report);

      if (defaultUsers.length) {
        window.$('#technicians').val(defaultUsers.map(u => u.id.toString())).trigger('change');
      }
    },
    hideTechnicianHandler(technicianId) {
      this.hideTechnicians.push(technicianId);
    },
    loadAllReports() {
      this.hideTechnicians = [];
      window.$('#technicians').val([]).trigger('change');
    },
    loadReports() {
      this.reports = [];

      // if (this.filters.technicians.length) {
        this.loading = true;

        this.axios.get('reports/technician-summary', {
          params: this.filters
        }).then((response) => {
          this.reports = response.data.data;
        }).finally(() => {
          this.loading = false;
        })
      // }
    },
    changeDateRange(start, end) {
      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')

      this.loadReports();
    }
  }
}
</script>