<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-3">
          <h1 class="m-0">Company Report</h1>
        </div><!-- /.col -->
        <div class="col-sm-3">
          <div id="entry-date-range" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
            <i class="fa fa-calendar"></i>&nbsp;
            <span></span> <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div class="col-sm-5">
          <select class="select2"
                  id="companies"
                  multiple="multiple"
                  data-placeholder="Select Companies"
                  style="width: 100%;">
            <option :value="company.id"
                    v-for="company in companies"
                    :key="'company_'+company.id">{{ company.name }}</option>
          </select>
        </div>
        <div class="col-sm-1">
          <button class="btn btn-primary btn-block"
                  @click="loadAllReports">All</button>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div v-if="reports">
        <company-row title="Summary"
                   :filters="filters"
                     :hideCompanies="hideCompanies"
                     @hideCompanyHandler="hideCompanyHandler"
                   :data="reports.summary" />

        <company-row :title="'Week - ' + report.week_no + ' (' + report.week_start + ' - ' + report.week_end + ')'"
                   :key="'week_'+i"
                   :filters="filters"
                     :hideCompanies="hideCompanies"
                   v-for="(report, i) in reports.weekly"
                     @hideCompanyHandler="hideCompanyHandler"
                   :data="report.data" />
      </div>
    </div>
  </section>
</template>

<script>
import CompanyRow from "@/views/report/components/CompanyRow.vue";

export default {
  components: {CompanyRow},
  data() {
    return {
      reports: null,
      companies: [],
      hideCompanies: [],
      filters: {
        companies: [],
        date: ''
      },
    }
  },
  created() {
    this.axios.get('/companies')
      .then((response) => {
        this.companies = response.data.data
      })
  },
  mounted() {
    let start = window.moment().startOf('week');
    let end = window.moment();
    let self = this;

    window.$('.select2').select2();

    window.$('#companies').on('change.select2', function () {
      self.filters.companies = window.$(this).val();
      self.loadReports();
    });

    window.$('#entry-date-range').daterangepicker(
      {
        autoUpdateInput: false,
        startDate: start,
        endDate: end,
        locale: {
          cancelLabel: 'Clear',
          format: 'MMM D, YYYY'
        },
        ranges: {
          'This Week': [window.moment().startOf('week'), window.moment()],
          'Today': [window.moment(), window.moment()],
          'Last Week': [window.moment().subtract(7, 'days').startOf('week'), window.moment().subtract(7, 'days').endOf('week')],
          'Last 30 Days': [window.moment().subtract(29, 'days'), window.moment()],
          'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
          'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')],
          'This Year': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment()],
          'Year to Last Week': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment().subtract(1, 'weeks').endOf('week')],
        }
      },
      function (start, end) {
        self.changeDateRange(start, end);
      }
    )

    this.changeDateRange(start, end);
  },
  methods: {
    hideCompanyHandler(companyId) {
      this.hideCompanies.push(companyId);
    },
    loadAllReports() {
      this.hideCompanies = [];
      window.$('#companies').val([]).trigger('change');
    },
    changeDateRange(start, end) {
      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
      this.period = start.format('MMM DD, YYYY') + ' - ' + end.format('MMM DD, YYYY')

      this.loadReports();
    },
    loadReports() {
      this.reports = null;

      this.axios.get('reports/companies', {
        params: {...this.filters, companies: this.filters.companies.join(',')},
      }).then((response) => {
        this.reports = response.data.data;
      })
    },
  }
}
</script>