<template>
  <div class="modal fade" id="production-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Production Log</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-sm">
            <thead>
            <tr>
              <th>Date</th>
              <th>Company</th>
              <th>Invoice No.</th>
              <th>Type</th>
              <th class="text-right">Amount</th>
              <th class="text-right">Commission (%)</th>
              <th class="text-right">Commission</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="history in histories" :key="'history_'+history.id">
              <td>{{ history.date }}</td>
              <td>{{ history.company }}</td>
              <td>{{ history.invoice_no }}</td>
              <td>{{ history.type }}</td>
              <td class="text-right">${{ history.amount }}</td>
              <td class="text-right">{{ history.commission_percentage }}%</td>
              <td class="text-right">${{ history.commission.toFixed(2) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      histories: [],
      filters: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('production-modal'))
  },
  methods: {
    show(filters) {
      this.filters = filters;
      this.modal.show();
      this.loadData();
    },
    hide() {
      this.modal.hide();
    },
    loadData() {
      this.histories = [];

      this.axios.get('/reports/history/production', {
        params: this.filters
      }).then(res => {
        this.histories = res.data.data;
      })
    }
  }
}
</script>