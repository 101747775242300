<template>
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div :class="'card-header ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">{{ title }}</div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <table class="table table-bordered text-center">
                <thead>
                <tr>
                  <th style="vertical-align: middle; text-align: center" rowspan="2">Company</th>
                  <th class="bg-light-yellow" style="vertical-align: middle; text-align: center" rowspan="2">Jobs #</th>
                  <th class="bg-light-blue" colspan="3">Amount</th>
                  <th class="bg-light-pink" colspan="7">%</th>
                </tr>
                <tr>
                  <th class="bg-light-blue">Revenue</th>
                  <th class="bg-light-blue">Avg Job</th>
                  <th class="bg-light-blue">Avg COGS</th>
                  <th class="bg-light-pink">Wow</th>
                  <th class="bg-light-pink">Labour</th>
                  <th class="bg-light-pink">Gas</th>
                  <th class="bg-light-pink">Service</th>
                  <th class="bg-light-pink">Repairs</th>
                  <th class="bg-light-pink">Supplies</th>
                  <th class="bg-light-pink">COGS</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="companyData in data.companies"
                          :key="'company_data_'+companyData.id">
                  <tr v-if="!hideCompanies.includes(companyData.id)">
                    <td>
                      <a href="#"
                         @click.prevent="hideCompanyHandler(companyData.id)"
                         class="text-danger mr-1 text-sm"
                         v-if="!filters.companies.length">X</a>

                      {{ companyData.name }}
                    </td>
                    <td class="bg-light-yellow">{{ companyData.total_job }}</td>
                    <td class="bg-light-blue">${{ companyData.revenue.toFixed(2) }}</td>
                    <td class="bg-light-blue">${{ companyData.average_job_cost.toFixed(2) }}</td>
                    <td class="bg-light-blue">${{ companyData.avg_cogs.toFixed(2) }}</td>
                    <td class="bg-light-pink">{{ companyData.wow_score.toFixed(2) }}%</td>
                    <td class="bg-light-pink">{{ companyData.labour_cost_percentage }}%</td>
                    <td class="bg-light-pink">{{ companyData.gas_percentage }}%</td>
                    <td class="bg-light-pink">{{ companyData.service_percentage }}%</td>
                    <td class="bg-light-pink">{{ companyData.repair_percentage }}%</td>
                    <td class="bg-light-pink">{{ companyData.supply_percentage }}%</td>
                    <td class="bg-light-pink">{{ companyData.total_cogs_percentage }}%</td>
                  </tr>
                </template>
                </tbody>
                <tfoot>
                <tr>
                  <th></th>
                  <th class="bg-light-yellow">{{ data.companies.filter(company => !hideCompanies.includes(company.id)).reduce((a, b) => a + b.total_job, 0) }}</th>
                  <th class="bg-light-blue">${{ data.companies.filter(company => !hideCompanies.includes(company.id)).reduce((a, b) => a + b.revenue, 0).toFixed(2) }}</th>
                  <th class="bg-light-blue"></th>
                  <th class="bg-light-blue"></th>
                  <th class="bg-light-pink"></th>
                  <th class="bg-light-pink"></th>
                  <th class="bg-light-pink"></th>
                  <th class="bg-light-pink"></th>
                  <th class="bg-light-pink"></th>
                  <th class="bg-light-pink"></th>
                  <th class="bg-light-pink"></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['data', 'title', 'filters', 'hideCompanies'],
  methods: {
    hideCompanyHandler(companyId) {
      this.$emit('hideCompanyHandler', companyId);
    },
  }
}
</script>