<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-8">
          <h1 class="m-0">Technician</h1>
        </div><!-- /.col -->
        <div class="col-sm-4">
          <select class="form-control"
                  @change="loadData"
                  v-model="filters.technician">
            <option value="">Select Technician</option>
            <option :value="technician.id"
                    v-for="technician in technicians"
                    :key="'tech_'+technician.id">{{ technician.name }}</option>
          </select>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row" v-if="filters.technician && topWidget">
<!--        <div class="col-lg-3 col-6">
          &lt;!&ndash; small card &ndash;&gt;
          <div class="small-box bg-info" @click="$refs.walletHistoryModal.show('gross', topWidget.gross)">
            <div class="inner">
              <h3>${{ topWidget.gross.current.toFixed(2) }}</h3>

              <div class="row">
                <div class="col-md-12">
                  <p style="margin: 0">GROSS PAY WALLET</p>
                </div>
              </div>
            </div>
            <div class="icon">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>-->
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small card -->
          <div class="small-box bg-success">
            <div class="inner">
              <h3>${{ topWidget.commission.current.toFixed(2) }}</h3>

              <div class="row">
                <div class="col-md-12">
                  <p style="margin: 0">
                    BONUS WALLET

                    <i class="ml-1 fas fa-info-circle"
                       @click="$refs.walletHistoryModal.show('bonus', topWidget.commission)"
                       title="Details"></i>
                  </p>
                </div>
              </div>
            </div>
            <div class="icon">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small card -->
          <div class="small-box bg-gradient-blue">
            <div class="inner">
              <h3>${{ topWidget.wow.current.toFixed(2) }}</h3>

              <div class="row">
                <div class="col-md-12">
                  <p style="margin: 0">
                    WOWS WALLET

                    <i class="ml-1 fas fa-info-circle"
                       @click="$refs.walletHistoryModal.show('wow', topWidget.wow)"
                       title="Details"></i>
                  </p>
                </div>
              </div>
            </div>
            <div class="icon">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small card -->
          <div class="small-box bg-danger">
            <div class="inner">
              <h3>${{ topWidget.tip.current.toFixed(2) }}</h3>

              <div class="row">
                <div class="col-md-12">
                  <p style="margin: 0">
                    TIPS WALLETS

                    <i class="ml-1 fas fa-info-circle"
                       @click="$refs.walletHistoryModal.show('tip', topWidget.tip)"
                       title="Details"></i>
                  </p>
                </div>
              </div>
            </div>
            <div class="icon">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>
        <!-- ./col -->
      </div>

      <div class="row mb-4" v-show="filters.technician">
        <div class="col-sm-8"></div><!-- /.col -->
        <div class="col-sm-4">
          <div id="entry-date-range" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
            <i class="fa fa-calendar"></i>&nbsp;
            <span></span> <i class="fa fa-caret-down"></i>
          </div>
        </div>
      </div><!-- /.row -->

      <div v-if="reports">
        <technician-row title="Summary"
                        :filters="filters"
                        :data="reports.summary" />

        <technician-row :title="'Week - ' + report.week_no + ' (' + report.week_start + ' - ' + report.week_end + ')'"
                        :key="'week_'+i"
                        :filters="filters"
                        v-for="(report, i) in reports.weekly"
                        :data="report.data" />
      </div>
    </div>
  </section>

  <wallet-history-modal ref="walletHistoryModal" />
  <gross-wallet-history-modal ref="grossWalletHistoryModal" />
</template>

<script>
import WalletHistoryModal from "@/views/report/components/modals/WalletHistoryModal.vue";
import GrossWalletHistoryModal from "@/views/report/components/modals/GrossWalletHistoryModal.vue";
import TechnicianRow from "@/views/report/components/TechnicianRow.vue";
export default {
  components: {TechnicianRow, WalletHistoryModal, GrossWalletHistoryModal},
  data() {
    return {
      reports: null,
      topWidget: null,
      goalReports: [],
      technicians: [],
      period: '',
      reportFilter: {
        date: ''
      },
      filters: {
        technician: '',
        date: ''
      },
    }
  },
  created() {
    this.axios.get('/technicians')
        .then((response) => {
          this.technicians = response.data.data
        })
  },
  mounted() {
    let start = window.moment().startOf('week');
    let end = window.moment();
    let self = this;

    window.$('#entry-date-range').daterangepicker(
        {
          autoUpdateInput: false,
          startDate: start,
          endDate: end,
          locale: {
            cancelLabel: 'Clear',
            format: 'MMM D, YYYY'
          },
          ranges: {
            'This Week': [window.moment().startOf('week'), window.moment()],
            'Today': [window.moment(), window.moment()],
            'Last Week': [window.moment().subtract(7, 'days').startOf('week'), window.moment().subtract(7, 'days').endOf('week')],
            'Last 30 Days': [window.moment().subtract(29, 'days'), window.moment()],
            'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
            'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')],
            'This Year': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment()],
            'Year to Last Week': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment().subtract(1, 'weeks').endOf('week')],
          }
        },
        function (start, end) {
          self.changeDateRange(start, end);
        }
    )

    this.changeDateRange(start, end);
  },
  methods: {
    loadData() {
      if (this.filters.technician !== '') {
        this.axios.get('reports/technician-wallet', {
          params: this.filters
        }).then((response) => {
          this.topWidget = response.data.data;
          this.loadReports();
        })
      }
    },
    loadReports() {
      this.reports = null;

      if (this.filters.technician) {
        this.axios.get('reports/technician-goal/' + this.filters.technician, {
          params: this.reportFilter
        }).then((response) => {
          this.reports = response.data.data;
        })
      }
    },
    changeDateRange(start, end) {
      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.reportFilter.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
      this.period = start.format('MMM DD, YYYY') + ' - ' + end.format('MMM DD, YYYY')

      this.loadReports();
    }
  }
}
</script>