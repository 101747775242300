<template>
  <div class="modal fade" id="power-score-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Power Score Log</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-sm">
            <thead>
            <tr>
              <th>Start</th>
              <th>End</th>
              <th>Worked</th>
              <th>Personal EH Score</th>
              <th>Client EH Score</th>
              <th>Communication EH Score</th>
              <th>Power Score</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="history in histories" :key="'history_'+history.id">
              <td>{{ history.start }}</td>
              <td>{{ history.end }}</td>
              <td>{{ history.worked }}</td>
              <td>{{ history.personal }}</td>
              <td>{{ history.client }}</td>
              <td>{{ history.communication }}</td>
              <td>{{ history.power_score }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      histories: [],
      filters: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('power-score-modal'))
  },
  methods: {
    show(filters) {
      this.filters = filters;
      this.modal.show();
      this.loadData();
    },
    hide() {
      this.modal.hide();
    },
    loadData() {
      this.histories = [];

      this.axios.get('/reports/history/power-score', {
        params: this.filters
      }).then(res => {
        this.histories = res.data.data;
      })
    }
  }
}
</script>