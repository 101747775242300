<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-2">
          <h1 class="m-0">Truck</h1>
        </div><!-- /.col -->
        <div class="col-sm-4">
          <div id="entry-date-range" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
            <i class="fa fa-calendar"></i>&nbsp;
            <span></span> <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div class="col-sm-5">
          <select class="select2"
                  id="trucks"
                  multiple="multiple"
                  data-placeholder="Select Trucks"
                  style="width: 100%;">
            <option :value="truck.id"
                    v-for="truck in trucks"
                    :key="'truck_'+truck.id">{{ truck.name }}</option>
          </select>
        </div>
        <div class="col-sm-1">
          <button class="btn btn-primary btn-block"
                  @click="loadAllReports">All</button>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div v-if="reports">
        <truck-row title="Summary"
                   :filters="filters"
                   :hideTrucks="hideTrucks"
                   @hideTruckHandler="hideTruckHandler"
                   :data="reports.summary" />

        <truck-row :title="'Week - ' + report.week_no + ' (' + report.week_start + ' - ' + report.week_end + ')'"
                   :key="'week_'+i"
                   :filters="filters"
                   @hideTruckHandler="hideTruckHandler"
                   :hideTrucks="hideTrucks"
                   v-for="(report, i) in reports.weekly"
                   :data="report.data" />
      </div>
    </div>
  </section>
</template>

<script>
import TruckRow from "@/views/report/components/TruckRow.vue";

export default {
  components: {TruckRow},
  data() {
    return {
      reports: null,
      trucks: [],
      hideTrucks: [],
      filters: {
        trucks: [],
        date: ''
      },
    }
  },
  async mounted() {
    await this.loadTrucks();
    this.initDateRange();
    this.initSelect2();
  },
  methods: {
    hideTruckHandler(truckId) {
      this.hideTrucks.push(truckId);
    },
    loadAllReports() {
      this.hideTrucks = [];
      window.$('#trucks').val([]).trigger('change');
    },
    async loadTrucks() {
      await this.axios.get('/trucks?exiled_from_report=0')
          .then(res => {
            this.trucks = res.data.data;
          })
    },
    initDateRange() {
      let start = window.moment().startOf('week');
      let end = window.moment();
      let self = this;

      window.$('#entry-date-range').daterangepicker(
          {
            autoUpdateInput: false,
            startDate: start,
            endDate: end,
            locale: {
              cancelLabel: 'Clear',
              format: 'MMM D, YYYY'
            },
            ranges: {
              'This Week': [window.moment().startOf('week'), window.moment()],
              'Today': [window.moment(), window.moment()],
              'Last Week': [window.moment().subtract(7, 'days').startOf('week'), window.moment().subtract(7, 'days').endOf('week')],
              'Last 30 Days': [window.moment().subtract(29, 'days'), window.moment()],
              'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
              'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')],
              'This Year': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment()],
              'Year to Last Week': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment().subtract(1, 'weeks').endOf('week')],
            }
          },
          function (start, end) {
            self.changeDateRange(start, end);
          }
      )

      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
    },
    initSelect2() {
      let self = this;

      window.$('.select2').select2();

      window.$('#trucks').on('change.select2', function () {
        self.filters.trucks = window.$(this).val();
        self.loadReports();
      });

      const defaultTrucks = this.trucks.filter(t => t.include_in_default_report);

      if (defaultTrucks.length) {
        window.$('#trucks').val(defaultTrucks.map(u => u.id.toString())).trigger('change');
      }
    },
    changeDateRange(start, end) {
      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')
      this.period = start.format('MMM DD, YYYY') + ' - ' + end.format('MMM DD, YYYY')

      this.loadReports();
    },
    loadReports() {
      this.reports = null;

      this.axios.get('reports/trucks', {
        params: {...this.filters, trucks: this.filters.trucks.join(',')},
      }).then((response) => {
        this.reports = response.data.data;
      })
    },
  }
}
</script>